/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import { EQUIPMENT_CONFIG } from './config';
import {
    EQ_TYPE,
    GLOVES_INDEX,
    KNIFES_INDEX,
    TEAM,
    WEAPONS_GROUP,
    WEAPON_TYPE,
} from '../../types/enums';
import {
    EquippedItemPreview,
    OtherEq,
} from './components';
import { IEquipmentResponse } from '../../types/Equipment';
import EquipmentSkinPreview from '../../shared/components/EquipmentSkinPreview';
import Loader from '../../shared/components/Loader/Loader';
import React, {
    useEffect,
    useState,
} from 'react';
import axios from 'axios';
import classNames from 'classnames';
import getWear from '../../shared/helpers/getWear';
import styles from './MyEquipment.module.scss';

interface IUsedItems {
    eq: {
        [TEAM.CT]: {
            [key: string]: IEquipmentResponse;
        };
        [TEAM.TT]: {
            [key: string]: IEquipmentResponse;
        };
    };
    usedKnife?: {
        ct: number;
        tt: number;
    };
}

export const MyEquipment = () => {
    const [equippedLoading, setEquippedLoading] = useState(false);
    const [myItemsLoading, setMyItemsLoading] = useState(false);
    const [equippedItems, setEquippedItems] = useState<IUsedItems>({
        eq: {
            ct: {},
            tt: {},
        },
    });
    const [usedKnifes, setUsedKnifes] = useState<{
        ct: IEquipmentResponse;
        tt: IEquipmentResponse;
    }>({
        ct: null as unknown as IEquipmentResponse,
        tt: null as unknown as IEquipmentResponse,
    });
    const [usedGloves, setUsedGloves] = useState<{
        ct: IEquipmentResponse;
        tt: IEquipmentResponse;
    }>({
        ct: null as unknown as IEquipmentResponse,
        tt: null as unknown as IEquipmentResponse,
    });

    const [selectedTeam, setSelectedTeam] = useState(TEAM.CT);
    const [selectedGroup, setSelectedGroup] = useState(WEAPONS_GROUP.PISTOLS);
    const [selectedWeapon, setSelectedWeapon] = useState(undefined as unknown as WEAPON_TYPE);
    const [myItems, setMyItems] = useState([] as IEquipmentResponse[]);

    const getKnife = (usedKnife: number) => {
        return Object.entries(KNIFES_INDEX).find(([, value]) => value === usedKnife) as [WEAPON_TYPE, number];
    };

    const getGloves = (usedGlove: number) => {
        return Object.entries(GLOVES_INDEX).find(([, value]) => value === usedGlove) as [WEAPON_TYPE, number];
    };

    const getEquippedItems = () => {
        setEquippedLoading(true);
        axios.get('/api/equipped-items').then(({ data }) => {
            setEquippedItems(data);
            setEquippedLoading(false);

            const KnifeCt = getKnife(data.usedKnife.ct);
            const KnifeTt = getKnife(data.usedKnife.tt);

            setUsedKnifes({
                ct: data.eq.ct[KnifeCt[0]],
                tt: data.eq.tt[KnifeTt[0]],
            });

            const GlovesCt = getGloves(data.usedGloves.ct);
            const GlovesTt = getGloves(data.usedGloves.tt);

            setUsedGloves({
                ct: data.eq.ct[GlovesCt[0]],
                tt: data.eq.tt[GlovesTt[0]],
            });
        });
    };

    const getAllEquipment = () => {
        selectedWeapon && setMyItemsLoading(true);
        selectedWeapon && axios.get(`/api/get-equipment?weapon_type=${selectedWeapon}`).then(({ data }) => {
            setMyItems(data.items);
            setMyItemsLoading(false);
        });
    };

    useEffect(() => {
        getEquippedItems();
    }, []);

    useEffect(
        () => {
            getAllEquipment();
        },
        [selectedWeapon],
    );

    const weapons = {
        midTier: EQUIPMENT_CONFIG.weapons[WEAPONS_GROUP.MID_TIER][selectedTeam],
        pistols: EQUIPMENT_CONFIG.weapons[WEAPONS_GROUP.PISTOLS][selectedTeam],
        rifles: EQUIPMENT_CONFIG.weapons[WEAPONS_GROUP.RIFLES][selectedTeam],
    };

    const weaponsGroups = [
        {
            group: WEAPONS_GROUP.PISTOLS,
            name: 'Pistolety',
        },
        {
            group: WEAPONS_GROUP.MID_TIER,
            name: 'Pośrednie',
        },
        {
            group: WEAPONS_GROUP.RIFLES,
            name: 'Karabiny',
        },
    ];

    const handleGroupClick = (group: WEAPONS_GROUP) => setSelectedGroup(group);

    const handleWeaponClick = (weaponType: WEAPON_TYPE) => {
        setSelectedWeapon(weaponType);
    };

    const handleUseItem = (id: string) => {
        setMyItemsLoading(true);

        axios.post('/api/equip-item', { itemId: id, team: selectedTeam }).then(({ data }) => {
            setMyItemsLoading(false);
            setEquippedItems(data);
            setEquippedLoading(false);

            const KnifeCt = getKnife(data.usedKnife.ct);
            const KnifeTt = getKnife(data.usedKnife.tt);

            setUsedKnifes({
                ct: data.eq.ct[KnifeCt[0]],
                tt: data.eq.tt[KnifeTt[0]],
            });

            const GlovesCt = getGloves(data.usedGloves.ct);
            const GlovesTt = getGloves(data.usedGloves.tt);

            setUsedGloves({
                ct: data.eq.ct[GlovesCt[0]],
                tt: data.eq.tt[GlovesTt[0]],
            });
        });
    };

    const handleEqClick = (type: EQ_TYPE) => {
        if (EQ_TYPE.KNIFE === type) {
            handleWeaponClick(WEAPON_TYPE.KNIFE_CSS);
        }
        if (EQ_TYPE.GLOVES === type) {
            handleWeaponClick(WEAPON_TYPE.GLOVES_BLOODHOUND);
        }
        if (EQ_TYPE.ZEUS === type) {
            handleWeaponClick(WEAPON_TYPE.TASER);
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.loader}>
                {equippedLoading && <Loader /> }
            </div>

            <div className={styles.usedEqWrapper}>
                <div
                    className={classNames(
                        styles.ctTeamWrapper,
                        selectedTeam === TEAM.CT && styles.imageWrapperCtActive,
                    )}
                >
                    <OtherEq
                        clickable={selectedTeam === TEAM.CT}
                        onClick={handleEqClick}
                        team={TEAM.CT}
                        usedEq={{
                            [EQ_TYPE.KNIFE]: usedKnifes.ct,
                            [EQ_TYPE.GLOVES]: usedGloves.ct,
                            [EQ_TYPE.ZEUS]: equippedItems.eq.ct[WEAPON_TYPE.TASER],
                        }}
                    />
                </div>

                <div
                    className={classNames(
                        styles.imageWrapper,
                        styles.imageWrapperCt,
                        selectedTeam === TEAM.CT && styles.imageWrapperCtActive,
                    )}
                    onClick={() => setSelectedTeam(TEAM.CT)}
                >
                    <img
                        alt="ct-skin"
                        className={styles.agentImage}
                        src="https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/agent-4714.png"
                    />
                </div>

                <div className={classNames(
                    selectedTeam === TEAM.CT
                        ? styles.separatorCt
                        : styles.separatorTt,
                )} />

                <div
                    className={classNames(
                        styles.weaponsWrapper,
                        selectedTeam === TEAM.TT
                            ? styles.imageWrapperTtActive
                            : styles.imageWrapperCtActive,
                    )}
                >
                    <div className={styles.group}>
                        {weaponsGroups.map(({ group, name }) => (
                            <div
                                className={classNames(
                                    styles.groupItem,
                                    selectedGroup === group && styles.groupItemActive,
                                )}
                                key={group}
                                onClick={() => handleGroupClick(group)}
                            >
                                {name}
                            </div>
                        ))}
                    </div>

                    <div className={styles.weaponsContainer}>
                        {weapons[selectedGroup].map((weaponType) => (
                            <EquippedItemPreview
                                customItem={equippedItems.eq[selectedTeam][weaponType]}
                                key={weaponType}
                                onClick={() => handleWeaponClick(weaponType)}
                                weaponType={weaponType}
                            />
                        ))}
                    </div>
                </div>

                <div className={classNames(
                    styles.separatorCt,
                    selectedTeam === TEAM.CT
                        ? styles.separatorCt
                        : styles.separatorTt,
                )} />

                <div
                    className={classNames(
                        styles.imageWrapper,
                        styles.imageWrapperTt,
                        selectedTeam === TEAM.TT && styles.imageWrapperTtActive,
                    )}
                    onClick={() => setSelectedTeam(TEAM.TT)}
                >
                    <img
                        alt="tt-skin"
                        className={styles.agentImage}
                        src="https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/agent-4613.png"
                    />
                </div>

                <div
                    className={classNames(
                        styles.ttTeamWrapper,
                        selectedTeam === TEAM.TT && styles.imageWrapperTtActive,
                    )}
                >
                    <OtherEq
                        clickable={selectedTeam === TEAM.TT}
                        onClick={handleEqClick}
                        team={TEAM.TT}
                        usedEq={{
                            [EQ_TYPE.KNIFE]: usedKnifes.tt,
                            [EQ_TYPE.GLOVES]: usedGloves.tt,
                            [EQ_TYPE.ZEUS]: equippedItems.eq.tt[WEAPON_TYPE.TASER],
                        }}
                    />
                </div>
            </div>
            <div className={styles.myEquipmentWrapper}>
                {myItemsLoading
                    ? <Loader />
                    : myItems.length > 0
                        ? myItems.map(item => (
                            <EquipmentSkinPreview
                                float={item.float}
                                icon={item.icon}
                                isStatTrack={item.statTrack}
                                key={item._id}
                                name={item.name}
                                onClick={() => handleUseItem(item._id)}
                                price={item.price}
                                rarity={item.rarity }
                                // selected={selectedWeaponType === weaponType}
                                weaponType={item.weaponType}
                                wear={getWear(item.float)}
                            />
                        ))
                        : 'Ni ma nic'
                }
            </div>
        </div>
    );
};

export default MyEquipment;
